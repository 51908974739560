import NetworkUtil from "./NetworkUtil";
import { ServerResponse } from "../types/ServerResponse";

export default class ExemptionAPI {

  public static async filterExemptions(filter): Promise<{ success: boolean, reason: string, data }> {
    return NetworkUtil.makePost("/api/admin/exemption/filter", filter)
  }

  public static async newExemption(body): Promise<ServerResponse> {
    return await NetworkUtil.makePost("/api/admin/exemption/new", body);
  }

  public static async editExemption(body): Promise<ServerResponse> {
    return await NetworkUtil.makePost("/api/admin/exemption/edit", body);
  }

  public static async approveExemption(body): Promise<ServerResponse> {
    return await NetworkUtil.makePost("/api/admin/exemption/approve", body);
  }

  public static async denyExemption(body): Promise<ServerResponse> {
    return await NetworkUtil.makePost("/api/admin/exemption/deny", body);
  }

  // returns filePath of result report that is generated and stored in the cloud
  public static generateCertificateForReqNum(reqNum: string): Promise<{ success: boolean, reason: string, data }> {
    return NetworkUtil.makePost("/api/admin/pdfReport/generateResultReportForReqNum", { reqNum: reqNum })
  }

  public static viewCertificate(path: string): Promise<any> {
    return NetworkUtil.makePostForBuffer("/api/admin/pdfReport/viewCertificate", { path: path });
  }

  public static async getExemptionForApproval(body): Promise<ServerResponse> {
    return NetworkUtil.makePost("/api/admin/exemption/physicianapproval", body)
  }

  public static async updateExemptionStatus(exemption, status): Promise<ServerResponse> {
    if (status) {
      return await NetworkUtil.makePost("/api/admin/exemption/approve", exemption);
    } else {
      return await NetworkUtil.makePost("/api/admin/exemption/deny", exemption);
    }
  }

  public static getCertificateListForReqNum(reqNum: string): Promise<{ success: boolean, reason: string, data: [] }> {
    return NetworkUtil.makePost("/api/admin/pdfReport/getCertificateListForReqNum", { reqNum: reqNum });
  }


  public static async getFilteredExemptions(page, filter): Promise<{ exemptions, num_pages: number, totalExemptions: number, table_data?}> {
    return NetworkUtil.makePost("/api/admin/exemption/search?page=" + page, filter);
  }

  public static async getFilteredPhysicianExemptions(page, filter): Promise<{ num_pages: number, totalExemptions: number, table_data?}> {
    return NetworkUtil.makePost("/api/admin/exemption/approvalsearch?page=" + page, filter);
  }

  public static async getFilteredExemptionsForApproval(filter): Promise<{ success: boolean, reason?, record?}> {
    return NetworkUtil.makePost("/api/system/exemption/approvalsearch", filter);
  }

  public static async sendPhysicianEmail(exemption): Promise<ServerResponse> {
    return NetworkUtil.makePost("/api/admin/exemption/resend", exemption);
  }
}