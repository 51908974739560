import React from "react";
import { hideModal } from "../../util/FormatUtil";
import Select from "react-select";
import SamplesAPI from "../../network/SamplesAPI";
import { sweetalert } from "../../App";
import ExemptionAPI from "../../network/ExemptionAPI";

interface ReportSelectModalProps {
    header: string
    reqNum: any
    zIndex?
    handleViewPDF?
    handleInBetweenOverlay?
}

interface ReportSelectModalState {
    options?
    selectedReport?
    showLoading:boolean
}
export class ReportSelectModal extends React.Component<ReportSelectModalProps, ReportSelectModalState>{
    public static ID = "reportselect";
    constructor(props) {
        super(props);
        this.state = {
            options: [],
            selectedReport: null,
            showLoading:false
        }
        this.buildCard = this.buildCard.bind(this);
    }

    componentWillReceiveProps(
        nextProps: Readonly<ReportSelectModalProps>,
        nextContext: any
    ) {
        if (
            !!nextProps.reqNum && nextProps.reqNum !== ''
        ) {
            ExemptionAPI.getCertificateListForReqNum(nextProps.reqNum)
                .then(response => {
                    let options = response.data?.map((i) => {
                        let regex = /(.+\/)*(.+)\/(.+)$/
                        let splitPath = regex.exec(i) // showing only the filename, not the entire filePath
                        let label = splitPath[splitPath.length - 1]
                        return { label: label, value: i }
                    })
                    if (options && options.length && options.length > 1) {
                        let groupedOptions = [
                            { label: "Most Recent", options: options.slice(0, 1) },
                            { label: "Older Revisions", options: options.slice(1) }
                        ];
                        // set all options, pre-select the most recent version
                        this.setState({ options: groupedOptions, selectedReport: options[0] })
                    } else if(options && options.length && options.length === 1){
                        // preselect the first/only option
                        this.setState({ options: options, selectedReport: options[0]})
                    } else {
                        // preselect Generate Report - will process null value in onClick callback
                        let option = [{label:"Generate Report", value: null}]
                        this.setState({options: option, selectedReport: option[0]})
                    }
                });
        }
    }

    buildCard(){
        if(
            this.state.options &&
            this.state.options.length &&
            this.state.options.length > 0 &&
            (this.state.options.filter(o => o.label.includes('Generate')).length > 0)
        ){
            // display button 'Generate Report' 
            return (
                <>
                    <div className="card-body">
                        <div>Certificates are ready for viewing</div>
                    </div>
                    <div className="card-footer">
                        <button type="button" className="btn btn-primary"
                            onClick={() => {
                                this.props.handleViewPDF(this.state.selectedReport.value);
                                this.setState({ selectedReport: null }, () => {
                                    hideModal(ReportSelectModal.ID)
                                })
                            }}> Generate Certificate
                        </button>
                        <span className="pl-2 text-primary" style={window.innerWidth < 500 ? {fontSize: '0.80rem'}: {}}>Please ensure pop-ups are disabled to view certificate</span>
                    </div>
                </>
            )
        } else {
            // display select dropdown and button for 'View'
            return (
                <>
                    <div className="card-body">
                        <Select
                            placeholder={'Select Certificate ...'}
                            className={'custom-select-yo'}
                            value={this.state.selectedReport}
                            onChange={(e) => this.setState((prevState) => ({
                                selectedReport: e
                            }))}
                            options={this.state.options}
                        />
                    </div>
                    <div className="card-footer">
                        <button type="button" className="btn btn-primary"
                            onClick={() => {
                                if(!this.state.selectedReport.value){
                                    return sweetalert.fire({icon: 'error', title: '', text: 'Could not generate certificate at this time.'})
                                }
                                this.props.handleViewPDF(this.state.selectedReport.value);
                                this.setState({ selectedReport: null }, () => {
                                    this.props.handleInBetweenOverlay()
                                    hideModal(ReportSelectModal.ID)
                                })
                            }}> View
                        </button>
                        <span className="pl-2 text-primary" style={window.innerWidth < 500 ? {fontSize: '0.80rem'} : {}}>Please ensure pop-ups are disabled to view certificate</span>
                    </div>
                </>
            )
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="modal fade form_modal" id={ReportSelectModal.ID} tabIndex={-1}
                    style={{ zIndex: this.props.zIndex }} role="dialog"
                    aria-labelledby="result_modal_label"
                    aria-hidden="true" >
                    <div className="modal-dialog modal-lg modal-xl"
                        style={{ "maxWidth": "580px" }} role="document">
                        <div className="modal-content">
                            <div className="modal-body p-0 m-0">
                                <div className="container-fluid">
                                    <div className={"row"}>
                                        <div className="col-12 py-2">
                                            <div className="card mt-2">
                                                <div className="card-header verlag-bold">
                                                    <h4>{this.props.header}
                                                        <button style={{ outline: 'none' }} type="button"
                                                            className="close pr-4"
                                                            aria-label="Close"
                                                            onClick={() => {
                                                                this.setState({ selectedReport: null}, () => {
                                                                    this.props.handleInBetweenOverlay()
                                                                    hideModal(ReportSelectModal.ID)
                                                                })
                                                            }}>
                                                            <span aria-hidden="true"
                                                                style={{ fontSize: '1.5em', fontWeight: 'bold' }}
                                                            >&times;
                                                            </span>
                                                        </button>
                                                    </h4>
                                                </div>
                                                {this.buildCard()}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}