import React, { Component } from 'react';
import Overlay from "../Overlay";
import SimpleTable from "../tables/SimpleTable";
import { ReactSelect } from "../FilterCard";
import {
  getLabel,
  getNumPages,
  getPageOptions,
  hideModal,
  showModalNoOutsideClick,
  slicePages,
} from "../../util/FormatUtil";
import { sweetalert } from "../../App";
import Select from "react-select";
import ExemptionRecordModal from '../modals/ExemptionRecordModal';
import NetworkUtil from "../../network/NetworkUtil";
import { Column } from "../tables/TableBase";
import { Sorter, TableOrder } from "../../sorting/Sorter";
import PaginationTool from "../PaginationTool";
import SystemAPI from "../../network/SystemAPI";
import ExemptionRecordsFilterTabs from './ExemptionRecordsFilterTabs';
import ExemptionAPI from '../../network/ExemptionAPI';
import VaccineExemptionCertificate from '../resultReports/VaccineExemptionCertificate';
import moment from 'moment';
import AdminAPI from '../../network/AdminAPI';
import { ReportSelectModal } from '../modals/ReportSelectModal';

const ITEMS_PER_PAGE = 25;

interface ExemptionRecordsState {
  showLoading: boolean;
  selectedExemption;
  exemptionQueryResults;
  tableData: {}[];
  filter?
  direction?: TableOrder;
  page_options: ReactSelect[];
  selected_page?: { label; value }
  filteredObj?
  totalExemptions?
  searched?
  immunizations?
  showCertificate?
  races?
  genders?
  ethnicities?
  branding?
  states?
  countries?
  exemptionTypes?
  districts?
  schools?
  schoolOptions?
  isAdmin?: boolean
  grades?
  exemptionReasons?
  showLoadingResultReport?: boolean
  showLoadingForReportSelect?: boolean
}

export default class ExemptionRecords extends Component<
  any,
  ExemptionRecordsState
> {
  private exemptionFilterTabsRef: any;
  constructor(props) {
    super(props);

    this.state = {
      showLoading: false,
      selectedExemption: null,
      showLoadingResultReport: false,
      tableData: [] as any,
      exemptionQueryResults: [] as any,
      filteredObj: { Status: [null] },
      direction: "desc",
      page_options: [{ label: "1", value: "1" }],
      selected_page: { label: "1", value: 1 },
    };

    this.exemptionFilterTabsRef = React.createRef();
    this.viewPDFNewTab = this.viewPDFNewTab.bind(this);
  }

  componentDidMount(): void {
    document.title = 'Exemption Records | Oklahoma State Department of Health';
    AdminAPI.getPermissions().then((data) => {
      this.setState({ isAdmin: data.isAdmin });
    });
    SystemAPI.getAllGenders().then(data => {
      this.setState({ genders: data })
    })
    SystemAPI.getAllRaces().then(data => {
      this.setState({ races: data })
    })
    SystemAPI.getAllEthnicities().then(data => {
      this.setState({ ethnicities: data })
    })
    SystemAPI.getAllImmunizations().then(data => {
      this.setState({ immunizations: data })
    })
    SystemAPI.getProductBrandingFromURL(window.location.href).then(data => {
      this.setState({ branding: data })
    })
    SystemAPI.getAllStates().then(data => {
      this.setState({ states: data })
    })
    SystemAPI.getAllCountries().then(data => {
      this.setState({ countries: data })
    })
    SystemAPI.getAllExemptionTypes().then(data => {
      this.setState({ exemptionTypes: data })
    })
    SystemAPI.getAllSchoolDistricts().then(data => {
      this.setState({ districts: data })
    })
    SystemAPI.getAllSchools().then(data => {
      this.setState({ schools: data.schools })
    })
    SystemAPI.getAllSchoolGrades().then(data => {
      this.setState({ grades: data.grades })
    })
    SystemAPI.getAllPersonalExemptionReasons().then(data => {
      this.setState({ exemptionReasons: data })
    })

    this.setState({ showLoading: true }, async () => {
      let results = await ExemptionAPI.getFilteredExemptions(1, {
        filter: {
          //Default search for pending exemptions
          Status: [null]
        },
      });
      if (results.totalExemptions < 1) {
        sweetalert.fire({
          icon: "error",
          title: "",
          text: "No records found",
        });
        this.setState({ showLoading: false, tableData: [], totalExemptions: 0 });
        return;
      }

      let sorter = new Sorter<any>();

      this.setState({
        tableData: slicePages(sorter.sortByKey(
          results.table_data,
          "CreatedDate" as keyof any,
          "asc"
        ), 1, ITEMS_PER_PAGE),
        direction: this.state.direction === "asc" ? "desc" : "asc",
      });

      this.setState({
        // tableData: slicePages(results.data[0], 1, ITEMS_PER_PAGE),
        totalExemptions: results.totalExemptions.toString(),
        searched: this.state.filteredObj,
        page_options: getPageOptions(results.num_pages),
        showLoading: false,
        exemptionQueryResults: results.exemptions,
      });
    });
  }

  modalSubmitted() {
    hideModal(ExemptionRecordModal.ID);
    this.setState({ showLoading: true }, async () => {
      let result = await ExemptionAPI.getFilteredExemptions(1, {
        filter: this.state.filteredObj,
      });
      if (result.totalExemptions < 1) {
        this.setState({ showLoading: false, tableData: [], totalExemptions: 0 });
        return sweetalert.fire({
          title: "",
          html: "<p aria-live='polite'>Unable to filter Results data at this time</p>",
          icon: "error",
        });
      }

      this.setState({
        totalExemptions: result.totalExemptions.toString(),
        showLoading: false,
        page_options: getPageOptions(result.num_pages),
        tableData: result.table_data,
        searched: this.state.filteredObj,
      });
    });
  }

  openPDFNewTab(){
    let sampleService = this.props.services && this.props.selectedSample ? this.props.services.find(f => f.ID === this.props.selectedSample.ServiceID) : null;
    if(!sampleService){
        return sweetalert.fire({icon: 'error', title: '', text: 'Could not create report at this time'})
    }
    let reportType = sampleService.ReportType;
    if(!reportType){
        return sweetalert.fire({icon: 'error', title: '', text: 'Could not create report at this time'})
    }
    if(reportType >= 3){
      this.setState({ showLoadingForReportSelect: true }, async () => {
        let reason;
        try {
            // const response = await SamplesAPI.get6PlexPDFReport(this.props.selectedSample.ID);
            // reason = response && response.reason ? response.reason : '';
            // let data = await response.arrayBuffer();
            // const blob = new Blob([data], {type: 'application/pdf'});
            // const url = URL.createObjectURL(blob)
            // window.open(url, '_blank')
            showModalNoOutsideClick(ReportSelectModal.ID)
            this.setState({showLoadingForReportSelect: false})
        } catch (e) {
            this.setState({showLoadingForReportSelect: false})
            return sweetalert.fire({icon: 'error', title: '', text: reason})
        }
      });
    }
  }

  handleExportToCSV() {
    let filter = this.state.searched;

    sweetalert
      .fire({
        title: "Should your export include Patient Identifiers?",
        showCloseButton: true,
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: "Include",
        denyButtonText: `Don't include`,
      })
      .then((result) => {
        if (result.isConfirmed || result.isDenied) {
          let modifiedfilter = Object.assign(filter, {
            IncludePHI: result.isConfirmed ? true : false,
          });
          this.setState({ showLoading: true }, async () => {
            await NetworkUtil.downloadCSV(
              "/api/admin/exemption/csv",
              "Exemption Records.xlsx",
              { filter: modifiedfilter });
            this.setState({ showLoading: false });
          });
        }
      });
  }

  submit(page) {
    this.setState({ showLoading: true, tableData: [] }, async () => {
      hideModal(ExemptionRecordModal.ID);
      try {
        let result = await ExemptionAPI.getFilteredExemptions(page, {
          filter: this.state.filteredObj,
        });

        if (result.totalExemptions < 1) {
          sweetalert.fire({
            icon: "error",
            title: "",
            html: "<p aria-live='polite'>No records found</p>",
          });
          this.setState({ showLoading: false, tableData: [], totalExemptions: 0 });
          return;
        }
        hideModal(ExemptionRecordModal.ID);

        this.setState({
          totalExemptions: result.totalExemptions.toString(),
          showLoading: false,
          page_options: getPageOptions(result.num_pages),
          tableData: slicePages(result.table_data, 1, ITEMS_PER_PAGE),
          searched: this.state.filteredObj,
          exemptionQueryResults: result.exemptions,
        });
      } catch (e) {
        console.error(e);
        sweetalert.fire({
          icon: "error",
          title: "",
          text: "Search failed. Please try again.",
        });
        this.setState({ tableData: [], showLoading: false });
      }
    });
  }

  async viewPDFNewTab(path){
    let report;
    try {
        this.setState({showLoadingResultReport:true}, async ()=>{
          if (path === null) {
            let generate = await ExemptionAPI.generateCertificateForReqNum(this.state.selectedExemption.ReqNum);
            if (generate && generate.data) {
              report = await ExemptionAPI.viewCertificate(generate.data);
              let data = await report.arrayBuffer();
              const blob = new Blob([data], { type: 'application/pdf' });
              const url = URL.createObjectURL(blob);
              window.open(url, '_blank');
            }
          } else {
            report = await ExemptionAPI.viewCertificate(path);
            let data = await report.arrayBuffer();
            const blob = new Blob([data], { type: 'application/pdf' });
            const url = URL.createObjectURL(blob);
            window.open(url, '_blank');
          }
          this.setState({showLoadingResultReport:false})
        })
    } catch (error) {
      console.error(error);
      sweetalert.fire({icon: "error", title: "Error", text: `Error opening report for viewing`})
    }
  }

  changePage(page: number) {
    let allServices = this.state.exemptionQueryResults;
    let returnData = slicePages(allServices, page, ITEMS_PER_PAGE);
    this.setState({ tableData: returnData });
  }

  useSorter(col: Column) {

    let sorter = new Sorter<any>();

    this.setState({
      tableData: sorter.sortByKey(
        this.state.tableData,
        col.key as keyof any,
        this.state.direction
      ),
      direction: this.state.direction === "asc" ? "desc" : "asc",
    });
  }

  render() {
    return (
      <React.Fragment>
        <PaginationTool />
        <Overlay show_loading={this.state.showLoading} />
        <VaccineExemptionCertificate
          data={this.state.selectedExemption}
          exemptImmunizations={this.state.selectedExemption ? JSON.parse(this.state.selectedExemption?.ExemptImmunizations) : null}
          immunizations={this.state.immunizations}
          genders={this.state.genders}
          races={this.state.races}
          branding={this.state.branding}
        />
        <ExemptionRecordModal
          selectedExemption={this.state.selectedExemption}
          immunizations={this.state.immunizations}
          countries={this.state.countries}
          states={this.state.states}
          genders={this.state.genders}
          races={this.state.races}
          ethnicities={this.state.ethnicities}
          onClose={() => this.setState({ selectedExemption: null })}
          onSubmit={() => { this.modalSubmitted() }}
          isAdmin={this.state.isAdmin}
        />
        <ReportSelectModal header="Certificate"
          zIndex={100009}
          reqNum={this.state.selectedExemption?.ReqNum}
          handleInBetweenOverlay={() => { }}
          handleViewPDF={(filePath) => {
            this.viewPDFNewTab(filePath);
          }}
        />

        <div className="container-fluid ">
          <main id="main-content" tabIndex={-1} aria-label="Vaccine Exemptions">
            <div className="card m-2">
              <div className="card-header verlag-bold">
                <h3>Exemption Records</h3>
              </div>

              <ExemptionRecordsFilterTabs
                ref={this.exemptionFilterTabsRef}
                immunizations={this.state.immunizations}
                states={this.state.states}
                countries={this.state.countries}
                ethnicity={this.state.ethnicities}
                race={this.state.races}
                gender={this.state.genders}
                exemptionTypes={this.state.exemptionTypes}
                districts={this.state.districts}
                schools={this.state.schools?.map((school) => { return { label: school.SchoolName, value: school.SchoolID } })}
                grades={this.state.grades}
                reasons={this.state.exemptionReasons}
              />

              <div className="card-footer">
                <button
                  className={"btn immySubmitButtonOutline"}
                  onClick={() => {
                    let returnedFilteredObj = this.exemptionFilterTabsRef.current.preSubmitChecks()

                    if (!returnedFilteredObj) {
                      return
                    }
                    else {
                      this.setState({ selected_page: { label: 1, value: 1 }, filteredObj: returnedFilteredObj }, () => this.submit(1))
                    }
                  }}
                >
                  Search
                </button>
              </div>
            </div>
          </main>
          <div className="col-12 col-md-12 col-lg-12 pt-2">
            {this.state.tableData && this.state.tableData.length > 0 && (
              <div className="card mt-2 mb-5">
                <div className="card-header verlag-bold stickToTop" style={{ zIndex: 2 }}>
                  <h4 className="text-center text-md-left">
                    Exemptions
                    <button
                      className={
                        this.state.totalExemptions < 1
                          ? "d-none"
                          : "btn btn-outline-primary d-none d-md-inline-block d-xxl-inline-block ml-2"
                      }
                      onClick={() => this.handleExportToCSV()}
                    >
                      Export to CSV
                    </button>
                    <section className="tableHeaderSection float-md-right d-flex justify-content-around">
                      <h4 className={"float-md-right"} role="alert">
                        Total: {this.state.totalExemptions}
                      </h4>
                      <h4 className="d-inline-block float-right align-middle pr-2 ml-5">
                        Page{" "}
                      </h4>
                      <div className=" align-middle float-right pages ">
                        <Select
                          isSearchable={true}
                          placeholder={"1"}
                          noOptionsMessage={() => "No option"}
                          value={this.state.selected_page}
                          aria-label='Table Page Number'
                          onChange={(e: ReactSelect) =>
                            this.setState({ selected_page: e }, () =>
                              this.changePage(e.value)
                            )
                          }
                          className={"state_select"}
                          options={this.state.page_options}
                        />
                      </div>
                    </section>
                  </h4>
                </div>
                <div className="p-0 table-responsive">
                  <SimpleTable
                    columns={[
                      {
                        label: "Confirmation #",
                        key: "ReqNum",
                        popoverText: "Click to Sort By Confirmation #",
                        rawFormat: (val) => {
                          return (
                            <a
                              href={"#"}
                              className={'tableNameLinkColor'}
                              onClick={() => {
                                this.setState({ selectedExemption: val }, () =>
                                  showModalNoOutsideClick(
                                    ExemptionRecordModal.ID
                                  )

                                );
                              }}
                            >
                              {val.ReqNum}
                            </a>
                          );
                        },
                      },
                      {
                        label: "Name",
                        key: "Name",
                        popoverText: "Click to Sort By Name",
                        rawFormat: (val) => {
                          return (
                            <p>
                              {val.ChildMiddleInitial ? val.ChildFirstName + " " + val.ChildMiddleInitial + " " + val.ChildLastName : val.ChildFirstName + " " + val.ChildLastName}
                            </p>
                          );
                        },
                      },
                      {
                        label: "Exemption Type",
                        key: "ExemptionType",
                        popoverText: "Click to Sort By Exemption Type",
                        rawFormat: (val) => {
                          return <p>{getLabel(val.ExemptionType, this.state.exemptionTypes)?.label}</p>;
                        }
                      },
                      {
                        label: "Status",
                        key: "Status",
                        popoverText: "Click to Sort By Status",
                        rawFormat: (val) => {
                          return <p>{val?.Status ? "Approved" : val?.Status == 0 ? "Denied" : val?.ExemptionType === 5 && val?.PhysicianStatus == null ? "Pending (Physician)" : val?.ExemptionType === 5 && val?.PhysicianStatus === 1 ? "Pending (OSDH)" : "Pending"}</p>;
                        }
                      },
                      {
                        label: "Date/Time of Submission",
                        key: "CreatedDate",
                        popoverText: "Click to Sort By Created Date",
                        rawFormat: (val) => {
                          return <p>{moment(val?.CreatedDate).format("MM-DD-YYYY hh:mm a")}</p>;
                        }
                      },
                      {
                        label: "Certificate", key: "",
                        rawFormat: (val) => (
                          <button
                            type={'button'}
                            className={'btn btn-outline-primary'}
                            style={{ zIndex: 1 }}
                            onClick={() => { this.setState({ selectedExemption: val }, () => showModalNoOutsideClick(ReportSelectModal.ID)) }}
                            disabled={val.Status == null}
                          >
                            {"View Certificate"}
                          </button>)
                      }
                    ]}
                    table_data={this.state.tableData}
                    columnClickedCallback={(col) => {
                      this.useSorter(col);
                    }}
                  />
                </div>
              </div>
            )}
          </div>

        </div>
      </React.Fragment>
    );
  }
}
